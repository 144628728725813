import React from 'react';
import { NumericFormat } from 'react-number-format';
import Styles from './PlayersTableData.module.scss';

const PlayersTableData = (props) => {
  const { players } = props;

  const cellBackground = (data) => {
    if (data < 0) {
      return Styles.green;
    }
    if (data > 0) {
      return Styles.red;
    }
    return '';
  };

  return (
    <table className={Styles.playerTable}>
      <thead>
        <tr>
          <th>Position</th>
          <th>Player</th>
          <th>Current Score</th>
          <th>Prize Money</th>
          <th>Today</th>
          <th>Round</th>
          <th>Thru</th>
          <th>R1</th>
          <th>R2</th>
          <th>R3</th>
          <th>R4</th>
        </tr>
      </thead>
      <tbody>
        {players?.map((player, s) => {
          const { Stats } = player;
          return (
            <tr key={`${s}-${Stats.dg_id}`} className={Styles.dataRow}>
              <td>{Stats.current_pos}</td>
              <td>{Stats.player_name}</td>
              <td className={`${cellBackground(Stats.current_score)}`}>
                {Stats.current_score}
              </td>
              <td>
                <NumericFormat
                  value={Stats.purse_prize}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              </td>
              <td className={`${cellBackground(Stats.today)}`}>
                {Stats.today}
              </td>
              <td>{Stats.round}</td>
              <td>{Stats.thru}</td>
              <td>{Stats.R1}</td>
              <td>{Stats.R2}</td>
              <td>{Stats.R3}</td>
              <td>{Stats.R4}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PlayersTableData;
