import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import APIClient from '../../../helpers/APIClient';
import Styles from './AdminTournamentPage.module.scss';
import Header from '../../../components/Header/Header';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Button from '../../../components/Button/Button';

function AdminTournamentPage() {
  const { tournamentId } = useParams();
  const [tournamentData, setTournamentData] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const getTournamentData = () => {
    APIClient.getTournamentById(tournamentId, (res) => {
      if (res?.status === 200 && res?.data) {
        setTournamentData(res?.data);
      }
    });
  };

  const getTournamentUsersData = () => {
    APIClient.getTournamentUsersById(tournamentId, (res) => {
      if (res?.status === 200 && res?.data) {
        setUsersData(res?.data);
      }
    });
  };

  useEffect(() => {
    getTournamentUsersData();
    getTournamentData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className={Styles.liveHolder}>
        <p>
          <ButtonLink to={'/admin/tournaments'} label="Back To Tournaments" />
        </p>
        <p>
          <b>Tour Name:</b> {tournamentData.TourName}
        </p>
        <p>
          <b>Tournament Year:</b> {tournamentData.TournamentYear}
        </p>
        <p>
          <b>Tournament Name:</b> {tournamentData.TournamentName}
        </p>
        <p>
          <b>Tournament Status:</b> {tournamentData.TournamentStatusName}
        </p>
        <table className={Styles.liveTable}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              {/* <th>Team Name</th> */}
              <th>Players Selected</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {usersData?.map((data, i) => {
              return (
                <>
                  <tr key={`${i}-head`} className={Styles.dataRow}>
                    <td>{data.FirstName}</td>
                    <td>{data.LastName}</td>
                    {/* <td>{data.TeamName}</td> */}
                    <td>{data.TotalPlayersSelected}</td>
                    <td>
                      <ButtonLink
                        to={`/admin/tournaments/${tournamentId}/users/${data.UserId}`}
                        label="Edit"
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminTournamentPage;
