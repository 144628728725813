import axios from 'axios';

class APIClient {
  constructor() {
    const service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.basePath = process.env.REACT_APP_API_URL;
  }

  handleSuccess = (response) => {
    return response;
  };

  handleError = (error) => {
    return Promise.reject(error);
  };

  getAllPlayers = async (callback) => {
    await this.service
      .get(`${this.basePath}/v1/players`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getAllTournaments = async (callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getTournamentById = async (tournamentId, callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments/${tournamentId}`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getTournamentUsersById = async (tournamentId, callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments/${tournamentId}/users`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getTournamentUserById = async (userId, tournamentId, callback) => {
    await this.service
      .get(`${this.basePath}/v1/users/${userId}/tournaments/${tournamentId}`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  addPlayerToUserTournament = async (
    userId,
    tournamentId,
    playerId,
    callback,
  ) => {
    await this.service
      .post(
        `${this.basePath}/v1/users/${userId}/tournaments/${tournamentId}/players/${playerId}`,
      )
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  removePlayerFromUserTournament = async (
    userId,
    tournamentId,
    playerId,
    callback,
  ) => {
    await this.service
      .delete(
        `${this.basePath}/v1/users/${userId}/tournaments/${tournamentId}/players/${playerId}`,
      )
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getTournamentUsersLive = async (tournamentId, callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments/${tournamentId}/users/live`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getCurrentUsersLive = async (callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments/current/users/live`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };

  getCurrentLeaderboard = async (callback) => {
    await this.service
      .get(`${this.basePath}/v1/tournaments/current`)
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error?.response?.status, {});
      });
  };
}

export default new APIClient();
