import React from 'react';
import Styles from './AdminPage.module.scss';
import Header from '../../components/Header/Header';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

function AdminPage() {
  return (
    <>
      <Header></Header>
      <div className={Styles.leaderboardHolder}>
        <div className={Styles.comingSoonModal}>
          <ButtonLink to={'/admin/tournaments'} label="View Tournaments" />
        </div>
      </div>
    </>
  );
}

export default AdminPage;
