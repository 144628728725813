import React, { useEffect, useState, Fragment } from 'react';
import { NumericFormat } from 'react-number-format';
import APIClient from '../../helpers/APIClient';
import PlayersTableData from '../../components/LiveTeamRankings/PlayersTableData/PlayersTableData';
import Styles from './LiveLeaderboardPage.module.scss';
import Header from '../../components/Header/Header';

function LiveLeaderboardPage() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  let timer = false;

  const getLeaderboardData = () => {
    clearTimeout(timer);
    APIClient.getCurrentLeaderboard((res) => {
      if (res?.status === 200 && res?.data) {
        setLeaderboardData(res?.data);
        timer = setTimeout(getLeaderboardData, 60000);
      }
    });
  };

  const cellBackground = (data) => {
    if (data > 0) {
      return Styles.green;
    }
    if (data < 0) {
      return Styles.red;
    }
    return '';
  };

  const cellBackgroundReverse = (data) => {
    if (data < 0) {
      return Styles.green;
    }
    if (data > 0) {
      return Styles.red;
    }
    return '';
  };

  useEffect(() => {
    getLeaderboardData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className={Styles.liveHolder}>
        <table className={Styles.liveTable}>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Total</th>
              <th>Thru</th>
              <th>Today</th>
              <th>Prize Money</th>
              <th>SG Putt</th>
              <th>SG ARG</th>
              <th>SG APP</th>
              <th>SG OTT</th>
              <th>SG T2G</th>
              <th>SG TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData?.live_stats?.map((data, i) => {
              return (
                <Fragment key={i}>
                  <tr key={`${i}-head`} className={Styles.dataRow}>
                    <td>{data.position}</td>
                    <td>{data.player_name}</td>
                    <td className={`${cellBackgroundReverse(data.total)}`}>
                      {data.total}
                    </td>
                    <td>{data.thru}</td>
                    <td className={`${cellBackgroundReverse(data.round)}`}>
                      {data.round}
                    </td>
                    <td>
                      <NumericFormat
                        value={data.purse_prize}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </td>
                    <td className={`${cellBackground(data.sg_putt)}`}>
                      {data.sg_putt}
                    </td>
                    <td className={`${cellBackground(data.sg_arg)}`}>
                      {data.sg_arg}
                    </td>
                    <td className={`${cellBackground(data.sg_app)}`}>
                      {data.sg_app}
                    </td>
                    <td className={`${cellBackground(data.sg_ott)}`}>
                      {data.sg_ott}
                    </td>
                    <td className={`${cellBackground(data.sg_t2g)}`}>
                      {data.sg_t2g}
                    </td>
                    <td className={`${cellBackground(data.sg_total)}`}>
                      {data.sg_total}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LiveLeaderboardPage;
