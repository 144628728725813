import React from 'react';
import Styles from './ComingSoonPage.module.scss';
import Header from '../../components/Header/Header';

function ComingSoonPage() {
  return (
    <>
      <Header></Header>
      <div className={Styles.leaderboardHolder}>
        <div className={Styles.comingSoonModal}>
          <p>COMING SOON... CHECK BACK</p>
          <p>For now, please head to one of our viewing pages</p>
        </div>
      </div>
    </>
  );
}

export default ComingSoonPage;
