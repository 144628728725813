import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../../assets/images/icons/home_icon.png';
import HamburgerIcon from '../../assets/images/icons/hamburger_icon.png';
import Styles from './Header.module.scss';

const menuLinks = [
  {
    name: 'Live',
    link: '/live',
  },
  {
    name: 'Leaderboard',
    link: '/leaderboard',
  },
];

const Header = (props) => {
  const { label, customClass, onclick } = props;

  return (
    <div className={Styles.headerHolder}>
      <div className={Styles.homeHolder}>
        <Link to={'/'} className={Styles.homeButton}>
          <img src={HomeIcon} className={Styles.homeIcon} />
        </Link>
      </div>

      <div className={Styles.menuItems}>
        {menuLinks?.map((data, i) => {
          return (
            <Link to={data.link} className={Styles.menuButton} key={i}>
              {data.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
