import React, { useEffect, useState, Fragment } from 'react';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';
import { useParams } from 'react-router-dom';
import APIClient from '../../../helpers/APIClient';
import Button from '../../../components/Button/Button';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Styles from './AdminTournamentUserPage.module.scss';
import Header from '../../../components/Header/Header';

function AdminTournamentUserPage() {
  const { userId, tournamentId } = useParams();
  const [userData, setUserData] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState([]);

  const clearPlayerSelected = () => {
    setSelectedPlayer([]);
  };

  const onChange = (d) => {
    setSelectedPlayer(d);
  };

  const getTournamentUserData = () => {
    APIClient.getTournamentUserById(userId, tournamentId, (res) => {
      if (res?.status === 200 && res?.data) {
        setUserData(res?.data);
      }
    });
  };

  const getAllPlayersData = () => {
    APIClient.getAllPlayers((res) => {
      if (res?.status === 200 && res?.data) {
        setPlayersData(res?.data);
      }
    });
  };

  const addSelectedPlayer = () => {
    if (selectedPlayer !== null) {
      APIClient.addPlayerToUserTournament(
        userId,
        tournamentId,
        selectedPlayer,
        (res) => {
          if (res?.status === 200 && res?.data) {
            clearPlayerSelected();
            getTournamentUserData();
          }
        },
      );
    }
  };

  const removePlayerFromUserTournament = (playerId) => {
    APIClient.removePlayerFromUserTournament(
      userId,
      tournamentId,
      playerId,
      (res) => {
        if (res?.status === 200 && res?.data) {
          getTournamentUserData();
        }
      },
    );
  };

  useEffect(() => {
    getTournamentUserData();
    getAllPlayersData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className={Styles.liveHolder}>
        <p>
          <ButtonLink
            to={`/admin/tournaments/${tournamentId}`}
            label="Back To Tournament"
          />
        </p>
        <p>
          <b>First Name:</b> {userData.FirstName}
        </p>
        <p>
          <b>Last Name:</b> {userData.LastName}
        </p>
        {/* <p>
          <b>Team Name:</b> {userData.TeamName}
        </p> */}
        <p>
          <SelectSearch
            options={playersData}
            onChange={onChange}
            value={selectedPlayer}
            closeMenuOnSelect={false}
            placeholder="Choose a player"
            search
            isMulti
          />
          <Button onclick={() => addSelectedPlayer()} label="ADD" />
          <Button
            onclick={() => clearPlayerSelected()}
            label="CLEAR"
            customClass="reverse"
          />
        </p>
        <table className={Styles.liveTable}>
          <thead>
            <tr>
              <th>Player Name</th>
              <th>Country</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData?.Players?.map((data, i) => {
              return (
                <Fragment key={i}>
                  <tr key={`${i}-head`} className={Styles.dataRow}>
                    <td>
                      {data.player_name} {data.amateur > 0 && '(A)'}
                    </td>
                    <td>{data.country_code}</td>
                    <td>
                      <Button
                        onclick={() => removePlayerFromUserTournament(data.dg_id)
                        }
                        label="REMOVE"
                      />
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminTournamentUserPage;
