import React from 'react';
import Styles from './Button.module.scss';

const Button = (props) => {
  const { label, customClass, onclick } = props;

  return (
    <button
      className={`${Styles.btn} ${Styles[customClass]}`}
      onClick={() => onclick && onclick()}
    >
      {label ?? ''}
    </button>
  );
};

export default Button;
