import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LiveTeamRankingsPage from './pages/LiveTeamRankingsPage/LiveTeamRankingsPage';
import TournamentPage from './pages/TournamentPage/TournamentPage';
import ComingSoonPage from './pages/ComingSoonPage/ComingSoonPage';
import LiveLeaderboardPage from './pages/LiveLeaderboardPage/LiveLeaderboardPage';
import AdminPage from './pages/Admin/AdminPage';
import AdminTournamentsPage from './pages/Admin/AdminTournamentsPage/AdminTournamentsPage';
import AdminTournamentPage from './pages/Admin/AdminTournamentPage/AdminTournamentPage';
import AdminTournamentUserPage from './pages/Admin/AdminTournamentUserPage/AdminTournamentUserPage';
import AdminTournamentPlayersPage from './pages/Admin/AdminTournamentPlayersPage/AdminTournamentPlayersPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/admin/tournaments/:tournamentId/players/:playerId"
          element={<AdminTournamentPlayersPage />}
        />
        <Route
          path="/admin/tournaments/:tournamentId/users/:userId"
          element={<AdminTournamentUserPage />}
        />
        <Route
          path="/admin/tournaments/:tournamentId"
          element={<AdminTournamentPage />}
        />
        <Route path="/admin/tournaments" element={<AdminTournamentsPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/tournaments/:tournamentId" element={<TournamentPage />} />
        <Route path="/live" element={<LiveTeamRankingsPage />} />
        <Route path="/leaderboard" element={<LiveLeaderboardPage />} />
        <Route path="/" element={<LiveTeamRankingsPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
