import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../../helpers/APIClient';
import Styles from './AdminTournamentsPage.module.scss';
import Header from '../../../components/Header/Header';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Button from '../../../components/Button/Button';

function AdminTournamentsPage() {
  const [tournamentsData, setTournamentsData] = useState([]);

  const getTournamentData = () => {
    APIClient.getAllTournaments((res) => {
      if (res?.status === 200 && res?.data) {
        setTournamentsData(res?.data);
      }
    });
  };

  const cellBackground = (data) => {
    if (data === 'current') {
      return Styles.green;
    }
    if (data === 'closed') {
      return Styles.red;
    }
    return '';
  };

  useEffect(() => {
    getTournamentData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className={Styles.liveHolder}>
        <table className={Styles.liveTable}>
          <thead>
            <tr>
              <th>Tour</th>
              <th>Year</th>
              <th>Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tournamentsData?.map((data, i) => {
              return (
                <Fragment key={i}>
                  <tr key={`${i}-head`} className={Styles.dataRow}>
                    <td>{data.TourName}</td>
                    <td>{data.TournamentYear}</td>
                    <td>{data.TournamentName}</td>
                    <td
                      className={`${cellBackground(data.TournamentStatusCode)}`}
                    >
                      {data.TournamentStatusName}
                    </td>
                    <td>
                      <ButtonLink
                        to={`/tournaments/${data.TournamentId}`}
                        label="Stats"
                      />
                      <ButtonLink
                        to={`/admin/tournaments/${data.TournamentId}`}
                        label="Edit"
                        customClass="reverse"
                      />
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminTournamentsPage;
