import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './ButtonLink.module.scss';

const ButtonLink = (props) => {
  const { label, customClass, to } = props;

  return (
    <Link to={to ?? ''} className={`${Styles.link} ${Styles[customClass]}`}>
      {label ?? ''}
    </Link>
  );
};

export default ButtonLink;
