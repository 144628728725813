import React, { Fragment, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import APIClient from '../../helpers/APIClient';
import PlayersTableData from '../../components/LiveTeamRankings/PlayersTableData/PlayersTableData';
import Styles from './TournamentPage.module.scss';
import Header from '../../components/Header/Header';

function TournamentPage() {
  const { tournamentId } = useParams();
  const [liveData, setLiveData] = useState([]);
  let timer = false;

  const getLiveData = () => {
    clearTimeout(timer);
    APIClient.getTournamentUsersLive(tournamentId, (res) => {
      if (res?.status === 200 && res?.data) {
        setLiveData(res?.data);
        timer = setTimeout(getLiveData, 60000);
      }
    });
  };

  const toggleRow = (data, i) => {
    const nextData = data.map((d, a) => {
      if (a === i) {
        d.open = !d.open;
      }
      return d;
    });

    setLiveData(nextData);
  };

  useEffect(() => {
    getLiveData();
  }, []);

  return (
    <>
      <Header></Header>
      <div className={Styles.liveHolder}>
        <table className={Styles.liveTable}>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Prize Money</th>
            </tr>
          </thead>
          <tbody>
            {liveData?.map((data, i) => {
              return (
                <Fragment key={i}>
                  <tr
                    key={`${i}-head`}
                    className={Styles.dataRow}
                    onClick={() => toggleRow(liveData, i)}
                  >
                    <td>{data.Position}</td>
                    <td>
                      {data.FirstName} {data.LastName}
                    </td>
                    <td>
                      <NumericFormat
                        value={data.Total}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </td>
                  </tr>
                  {data.open && (
                    <tr key={`${i}-data`} className={Styles.playerRow}>
                      <td colSpan="3">
                        <PlayersTableData players={data.Players} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TournamentPage;
